import { inject } from "@angular/core";
import { ResolveFn, ActivatedRouteSnapshot, Router, RedirectCommand } from "@angular/router";
import { AuthService, ICurrentUser } from "../service/auth.service";

export const userResolver: ResolveFn<ICurrentUser | RedirectCommand> = (route: ActivatedRouteSnapshot) => {
    const router = inject(Router);
    const userService = inject(AuthService);

    return userService.getLoggedInUserDetails().then((user: ICurrentUser | undefined) => {
        if (user) {
            // Directly return the user as the resolved value
            return user;
        } else {
            // Return a RedirectCommand if the user is not found
            return new RedirectCommand(router.createUrlTree(['/not-found']), {
                skipLocationChange: true,
            });
        }
    }).catch(() => {
        // Handle any errors by redirecting to the login page
        return new RedirectCommand(router.createUrlTree(['/login']), {
            skipLocationChange: true,
        });
    });
};